<template>
  <div>
    <v-toolbar flat short dense class="grey lighten-5 sa-filter">
      <date-selector
        v-model="filter.from"
        :label="$t('general.from')"
        hide-icon
      />

      <v-divider vertical inset class="d-none d-md-flex mx-2" />
      <date-selector v-model="filter.to" hide-icon :label="$t('general.to')" />
      <v-spacer />
      <v-btn
        depressed
        x-small
        color="green"
        class="mx-2"
        dark
        @click="$emit('filter')"
        v-if="!auto"
      >
        <v-icon small> mdi-magnify </v-icon>
        {{ $t("general.search") }}
      </v-btn>
      <v-btn depressed x-small color="primary" dark @click="clear">
        <v-icon small> mdi-magnify-close </v-icon>
        {{ $t("general.clear") }}
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DateSelector from "@/common/components/DateSelector";

export default {
  name: "SitrepsFilter",
  // eslint-disable-next-line vue/no-unused-components
  components: { DateSelector },
  model: {
    prop: "filter",
    event: "input",
  },
  props: {
    auto: Boolean,
    filter: {
      type: Object,
      default() {
        return {
          from: null,
          to: null,
        };
      },
    },
  },
  mounted() {},
  computed: {
    ...mapGetters("types", ["getTypesByGroup"]),
  },
  methods: {
    ...mapActions("types", ["loadTypes"]),

    clear() {
      this.$emit("clear");
      this.$emit("filter");
    },
  },
};
</script>
