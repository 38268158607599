<template>
  <view-container breadcrumbs class="white py-0 px-0" fluid>
    <v-card flat>
      <toolbar :title="$t('srs.sitreps')">
        <v-text-field
          class="d-none d-md-flex"
          v-model="filter.search"
          append-icon="mdi-magnify"
          :label="$t('general.search')"
          single-line
          hide-details
          dense
          clearable
          style="width: 0"
        ></v-text-field>

        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-switch dense style="margin-top: 17px;" :label="$t('general.preview')" x-small color="blue-grey" v-model="togglePreview">
        </v-switch>
        <!-- <v-icon>mdi-file-find</v-icon> -->
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-btn text x-small color="green" @click="newAction">
          <v-icon>mdi-folder-plus</v-icon>
          <span class="text--secondary">{{ $t("general.new") }}</span>
        </v-btn>
        <v-btn
          text
          x-small
          color="primary"
          :disabled="!selected"
          @click="editAction()"
        >
          <v-icon>mdi-file-edit-outline</v-icon>
          <span>{{ $t("general.edit") }}</span>
        </v-btn>
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-btn
          text
          x-small
          color="red"
          v-if="!this.$hasRole('Administrator')"
          :disabled="!selected"
          @click="showDeleteRequest(selected)"
        >
          <v-icon>mdi-delete</v-icon>
          <span>{{ $t("general.deleteRequest") }}</span>
        </v-btn>
        <v-btn
          text
          x-small
          color="red"
          v-if="$hasRole('Administrator')"
          :disabled="isReadOnly || !selected"
          @click="deleteAction()"
        >
          <v-icon>mdi-delete</v-icon>
          <span>{{ $t("general.delete") }}</span>
        </v-btn>
      </toolbar>

      <sitreps-filter
        v-model="filter"
        @clear="clearFilter"
        @filter="loadItems"
        v-show="toggleFilter"
      />

      <v-card-text
        class="overflow-y-auto px-0 py-0"
        :style="viewHeight(toggleFilter ? 172 : 123)"
      >
        <v-row no-gutters>
          <v-col cols="12" :md="preview ? 8 : 12">
            <v-data-table
              dense
              :headers="getHeaders"
              :items="getSitreps"
              item-key="id"
              show-select
              single-select
              :value="selection"
              :loading="loading"
              :loading-text="$t('messages.loading')"
              single-expand
              show-expand
              :expanded.sync="expanded"
              :page.sync="page"
              :items-per-page="pageSize"
              hide-default-footer
              @item-selected="selectionChanged"
              :server-items-length="getSitrepsTotal"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              @update:sort-by="updateSort"
              @update:sort-desc="updateSort"
            >
              <template #item.data-table-select="{ item, isSelected, select }">
                <div class="d-flex">
                  <v-simple-checkbox
                    on-icon="mdi-checkbox-marked-circle"
                    off-icon="mdi-power-off"
                    :value="isSelected"
                    :color="item.deleteRequest ? 'red' : 'blue'"
                    light
                    @input="select($event)"
                    :ripple="false"
                    class="mx-0"
                  ></v-simple-checkbox>
                </div>
              </template>

              <template #item.name="{ item }">
                <a :href="getUrl(item.uuid)" class="text--primary">
                  {{ item.name }}
                </a>
              </template>
              <template #item.tags="{ item }">
                <v-chip
                  label
                  small
                  v-for="tag in getTags(item)"
                  :key="tag"
                  color="primary"
                  dark
                  class="mr-1"
                >
                  {{ tag }}
                </v-chip>
              </template>
            </v-data-table>

            <div
              class="text-center px-2 d-flex justify-space-between pt-2"
              style="border-top: 1px dashed #ccc"
            >
              <div class="ma-3">{{ getTotal }} Reports</div>
              <v-pagination
                v-model="page"
                total-visible="11"
                :length="
                  getSitrepsTotal > 0
                    ? Math.ceil(getSitrepsTotal / pageSize)
                    : 0
                "
                @input="loadItems"
              ></v-pagination>
              <v-select
                :value="pageSize"
                :items="[100, 500, 1000]"
                label="Reports per page"
                @input="pageSize = parseInt($event)"
                style="font-size: 10pt; max-width: 200px"
                solo
                dense
              ></v-select>
            </div>
          </v-col>
          <v-col v-if="preview" cols="12" md="4" class="pa-1">
            <v-card>
              <v-card-title>
                <v-icon>mdi-file-document</v-icon>
                <span class="ml-2">{{ selected.name }}</span>
              </v-card-title>
              <v-divider />
              <v-card-text :style="`padding: 10px; overflow-y: auto; ${viewHeight(245)}`">
                {{ selected.content }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </view-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ViewContainer from "@/common/layouts/ViewContainer";
import Toolbar from "@/common/components/Toolbar";
import SitrepsFilter from "./SitrepsFilter";
// import SitrepVerifyDialog from "./SitrepVerifyDialog";
import DeleteRequestDialog from "@/views/components/DeleteRequestDialog";
// import moment from "moment";

export default {
  name: "Sitreps",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    ViewContainer,
    Toolbar,
    SitrepsFilter,
    // eslint-disable-next-line vue/no-unused-components
    DeleteRequestDialog,
  },
  data() {
    return {
      togglePreview: false,
      page: 1,
      pageSize: 100,
      sortBy: "id",
      sortDesc: true,
      search: "",
      toggleFilter: true,
      multiSelection: [],
      loading: false,
      expanded: [],
      filter: {
        search: null,
      },
      filterReset: {
        search: null,
      },
    };
  },
  mounted() {
    this.loading = true;

    this.page = parseInt(this.$route.query.page || 1);
    this.pageSize = parseInt(this.$route.query.size) || this.pageSize;

    const sort = this.$route.query.sort || "id,desc";
    this.sortBy = sort?.split(",")[0] || "id";
    this.sortDesc = sort?.split(",")[1] !== "asc";

    this.loadItems();
  },
  computed: {
    ...mapGetters("sitreps", [
      "getSitreps",
      "getSitrepsTotal",
      "getSelectedSitrep",
    ]),

    preview() {
      return this.togglePreview && this.selected;
    },

    getHeaders() {
      return [
        {
          text: this.$t("general.id", "Id"),
          value: "id",
          width: 60,
          align: "center",
          sortable: true,
          sortBy: "id",
        },
        {
          text: this.$t("general.name", "Sitrep Name"),
          value: "name",
          sortable: true,
          sortBy: "name",
        },
        {
          text: this.$t("sitrep.sitrepDate", "Sitrep Date"),
          value: "sitrepDate",
          sortable: true,
          sortBy: "sitrep_date",
        },
        {
          text: this.$t("sitrep.tags", "Tags"),
          value: "tags",
          sortable: false,
          sortBy: "tags",
        },
      ];
    },

    selected() {
      return this.getSelectedSitrep;
    },
    selection() {
      return !this.getSelectedSitrep ? [] : [this.getSelectedSitrep];
    },
    getTotal() {
      const total = this.getSitrepsTotal;
      return total ? total.toLocaleString("en") : 0;
    },
  },
  methods: {
    ...mapActions("sitreps", [
      "loadSitrepsByParams",
      "loadPrivateSitrepsByParams",
      "deleteSitrep",
      "deleteSitrepRequest",
      "setSelectedSitrep",
      "clearSelectedSitrep",
    ]),
    ...mapActions("areas", ["loadAdmin1Areas"]),
    ...mapActions("ui", ["showDialog", "closeDialog"]),

    getTags(item) {
      return item.tags ? item.tags.split(";") : [];
    },

    getUrl(uuid) {
      return `${process.env.ROOT_API}/sitrep/download/${uuid}`;
    },

    loadItems() {
      this.loading = true;
      // const isAdmin = this.$hasRole("Administrator");

      let params = {
        ...this.filter,
        page: this.page - 1,
        size: this.pageSize,
      };

      let sort = null;
      const sortBy = this.getHeaders.find(
        (f) => f.value === this.sortBy
      )?.sortBy;
      if (sortBy) {
        sort = sortBy + (this.sortDesc ? ",desc" : ",asc");
        params = { ...params, sort };
      }

      // if (isAdmin) {
      this.loadSitrepsByParams(params)
        .then(() => {
          this.loading = false;
          this.updateQuery(params);
        })
        .catch(() => (this.loading = false));
      // } else {
      //   const id = this.$getUserInfo().organisationId;
      //   params = { ...params, organizationId: id };
      //   this.loadPrivateSitrepsByParams(params)
      //     .then(() => {
      //       this.loading = false;
      //       this.updateQuery(params);
      //     })
      //     .catch(() => (this.loading = false));
      // }
    },

    currentItemsChanged(e) {
      this.itemsCount = e.length;
    },

    selectionChanged(e) {
      if (e.value) {
        this.setSelectedSitrep(e.item);
      } else {
        this.clearSelectedSitrep();
      }
    },
    newAction() {
      this.$router.push({
        name: "sitrep",
        params: { sitrepId: "new" },
      });
    },
    editAction() {
      if (this.selected) {
        this.$router.push({
          name: "sitrep",
          params: { sitrepId: this.selected.id },
        });
      }
    },
    showDeleteRequest(item) {
      const dialog = {
        title: this.$t("general.deleteRequest"),
        component: DeleteRequestDialog,
        params: {
          item: { id: item.id, request: item.deleteRequest },
          editMode: true,
          undo: !!item.deleteRequest,
        },
        width: 450,
        actions: [
          {
            text: "general.yes",
            validate: true,
            click: ({ item, undo, valid }) => {
              if (!valid) {
                return;
              }

              if (undo) {
                item.request = null;
              }

              this.deleteSitrepRequest(item).then(
                () => (this.selected.deleteRequest = item.request)
              );
              this.closeDialog();
            },
            color: "primary",
          },
          {
            text: "general.no",
            click: () => this.closeDialog(),
            color: "primary",
          },
        ],
      };
      this.showDialog(dialog);
    },
    deleteAction() {
      if (this.selected) {
        // this.deleteDialog = true;
        const confirmMessageKey = "sitrep.confirmDelte";
        const dialog = {
          title: "Delete",
          text: this.$t(confirmMessageKey),
          actions: [
            {
              text: "general.yes",
              click: () => {
                this.deleteSitrep(this.selected.id);
                this.closeDialog();
              },
              color: "red",
            },
            {
              text: "general.no",
              click: () => this.closeDialog(),
              color: "primary",
            },
          ],
        };

        this.showDialog(dialog);
      }
    },
    clearFilter() {
      this.filter = { ...this.filterReset };
    },

    cutText(text) {
      return text && text.length > 100 ? text.substr(0, 100) : text;
    },

    updateQuery({ page, size, sort }) {
      if (
        !this.compareObjects(this.$route.query, { page: page + 1, size, sort })
      ) {
        this.$router.push({
          query: { ...this.$route.query, page: page + 1, size, sort },
        });
      }
    },

    updateSort() {
      if (this.sortBy === undefined || this.sortDesc === undefined) {
        this.sortBy = "id";
        this.sortDesc = true;
      } else {
        this.loadItems();
      }
    },
  },
};
</script>
